<template>
  <ion-list v-if="resources != null">
    <ion-list-header class="ion-margin-bottom">
      <h3>Exam Subjects</h3>
    </ion-list-header>
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item>
        <ion-label @click="openPopover(item, $event)">
          {{ item.subject.name }}
          <p>Class: {{ item.subject.standard.name }}</p>
          <p>
            {{
              item.exam_schedule
                ? moment(item.exam_schedule.start).format("ll") +
                  " " +
                  moment(item.exam_schedule.start).format("LT") +
                  " to " +
                  moment(item.exam_schedule.end).format("LT")
                : "Not Scheduled"
            }}
          </p>
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-align-items-center ion-no-padding">
              <ion-col>
                <p>
                  Marks assigned:
                  {{
                    item.exam_questions.reduce(
                      (total, question) => total + parseInt(question.marks),
                      0
                    )
                  }}
                </p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center ion-no-padding">
              <ion-col class="ion-no-padding">
                <p>Full: {{ item.full_mark }}</p>
              </ion-col>
              <ion-col>
                <p>Pass: {{ item.pass_mark }}</p>
              </ion-col>
              <ion-col>
                <p>Negative: {{ item.negative_percentage }}%</p>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No subjects found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="`${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`"
    >
      <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.question',
          params: {
            exam_subject_id: selectedResource.id,
            title: `${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`,
          },
        }"
        >Questions</ion-item
      >
      <ion-item
        :disabled = "exam_data.exam_type.name != 'Offline' || selectedResource.exam_subject_state.name != 'Evaluating'"
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.student-mark',
          params: {
            exam_subject_id: selectedResource.id,
            title: `${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`,
            exam_name: exam_data.name,
          },
        }"
        >Student Marks {{ selectedResource.exam_subject_state.name != 'Evaluating' ? `(${selectedResource.exam_subject_state.name})` : '' }}</ion-item
      >
      <ion-item
        :disabled = "exam_data.exam_type.name != 'Online' || selectedResource.exam_subject_state.name != 'Evaluating'"
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.evaluate',
          params: {
            exam_subject_id: selectedResource.id,
            title: `${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`,
            exam_name: exam_data.name,
          },
        }"
        >Evaluate {{ selectedResource.exam_subject_state.name != 'Evaluating' ? `(${selectedResource.exam_subject_state.name})` : '' }} </ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  alertController,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
} from "@ionic/vue";

import BasePopover from "../../../Base/BasePopover.vue";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    exam: {
      type: String,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonPopover,
    BasePopover,
  },
  data() {
    return {
      moment,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    exam_data() {
      return this.$store.getters["exam/specificResource"];
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },

    async fetchExam() {
      await this.$store.dispatch("exam/specificResource", this.exam);
    },

  },

  created() {
    this.fetchExam();
  },

  unmounted(){
    this.selectedResource = null;
  }
};
</script>