<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-subject :resources="resources" :exam="exam"></list-subject>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import ListSubject from "../../Components/Exam/ExamSubject/ListSubject";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";


export default {
  props: ["exam", "title"],

  components: {
    BaseLayout,
    ListSubject,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: true,
      
    };
  },

  computed: {
    resources() {
      return this.$store.getters["examSubject/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("examSubject/resources", {
        refresh: refresh,
        resourceId: this.exam,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("examSubject/resources", {
        refresh: true,
        resourceId: this.exam,
      });
      if (event) {
        event.target.complete();
      }
    },
  },

  async created() {
    await this.fetch();
  },
};
</script>

<style scoped>
</style>
